import jwt_decode from "jwt-decode";

class Auth {
  constructor() {
    this.authenticated = false;
    const userLocal = localStorage.getItem("t_am");
    if (userLocal !== null) {
      const user = jwt_decode(userLocal);
      if (user && user.role === "ADMIN") {
        this.authenticated = true;
      }
    }
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }

  user() {
    const userLocal = localStorage.getItem("t_am");
    if (userLocal) return jwt_decode(userLocal);
    return null;
  }
}

export default new Auth();
