import React from "react";

const LoadingButtonAnimation = () => {
  return (
    <>
      <img
        src="/assets/icons/loading.svg"
        alt="loading"
        style={{
          position: "absolute",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          margin: "auto",
        }}
      />
      &nbsp;
    </>
  );
};

export default LoadingButtonAnimation;
