import { IJobVacancy } from "../../../../shared/interface";

export const JobVacancyDto = (job: IJobVacancy) => ({
  title: job?.title,
  address: job?.address,
  description: job?.description,
  salary_from: job?.salary_from,
  salary_to: job?.salary_to,
  total_work: job?.total_work,
  skills_required: job?.skills_required,
  register_url: job?.register_url,
  status: job?.status,
  qualification: job?.qualification,
  type_work: job?.type_work,
  special_requirements: job?.special_requirements,
  job_field_type_id: job?.job_field_type?.id,
  job_company_id: job?.job_company?.id,
  expired_at: job?.expired_at,
  province_id: job?.province_id,
  city_id: job?.city_id,
  district_id: job?.district_id,
  village_id: job?.village_id,
});
