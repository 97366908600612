import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface IRadioField {
  fieldName: string;
  setFormValue: any;
  fieldLabel: string;
  options: any;
  fieldValue?: any;
}

export const RadioField = ({
  fieldName,
  setFormValue,
  fieldValue,
  fieldLabel,
  options,
}: IRadioField) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{fieldLabel}</FormLabel>
      <RadioGroup
        row
        aria-label={fieldName}
        name={fieldName}
        defaultValue={fieldValue}
        value={fieldValue || ""}
        onChange={handleChange}
      >
        {options &&
          options.map((item: { id: number; name: string }) => (
            <FormControlLabel
              value={item.id}
              control={<Radio color="primary" />}
              label={item.name}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};
