import React from "react";
import { makeStyles, Container, Grid, Paper, Box } from "@material-ui/core";
import Layout from "../../components/layout";
import Copyright from "../../components/footer/copyright";
import TableListJobs from "../../components/table/list-jobs";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const JobVacancyPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TableListJobs />
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  );
};

export default JobVacancyPage;
