import { useEffect } from "react";

export const useClose = () => {
  useEffect(() => {
    const handleTabClose = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();

      console.log("beforeunload event triggered");

      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
};
