import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import api from "../utils/api";
import jwt_decode from "jwt-decode";
import Copyright from "../components/footer/copyright";

interface IFormLogin {
  username: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  btnDisable: {
    backgroundColor: "#d4d4d4",
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  const [formLogin, setFormLogin] = useState<IFormLogin>({
    username: "",
    password: "",
  });
  const [isFormReady, setIsFormReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeForm = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormLogin({ ...formLogin, [name]: value });
  };

  useEffect(() => {
    if ((formLogin.username !== "", formLogin.password !== "")) {
      setIsFormReady(true);
    } else {
      setIsFormReady(true);
    }
  }, [formLogin.username, formLogin.password]);

  const handleSubmitLogin = async () => {
    setIsLoading(true);
    try {
      const { data: token } = await api.post("auth/login", formLogin);
      localStorage.setItem("t_am", token && token.access_token);
    } catch (error) {}
    setIsLoading(false);
  };

  const userLocal = localStorage.getItem("t_am");
  useEffect(() => {
    if (userLocal !== null) {
      const user: any = jwt_decode(userLocal);
      if (user && user.role === "ADMIN") {
        window.location.href = "/blog";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocal]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={handleChangeForm}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChangeForm}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.submit} ${isLoading && classes.btnDisable}`}
            onClick={handleSubmitLogin}
            disabled={!isFormReady && isLoading}
          >
            {isLoading ? (
              <img src="/assets/icons/loading.svg" alt="loading" />
            ) : (
              "Login"
            )}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginPage;
