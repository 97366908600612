import { IOption } from "../interface";

export const typeWorks: IOption[] = [
  { id: "Penuh Waktu", name: "Penuh Waktu" },
  { id: "Paruh Waktu", name: "Paruh Waktu" },
  { id: "Kontrak", name: "Kontrak" },
  { id: "Freelance", name: "Freelance" },
];

export const educations: IOption[] = [
  { id: "SD Sederajat", name: "SD Sederajat" },
  { id: "SMP Sederajat", name: "SMP Sederajat" },
  { id: "SMA Sederajat", name: "SMA Sederajat" },
  { id: "Diploma I", name: "Diploma I" },
  { id: "Diploma II", name: "Diploma II" },
  { id: "Diploma III", name: "Diploma III" },
  { id: "Diploma IV", name: "Diploma IV" },
  { id: "Sarjana (S1)", name: "Sarjana (S1)" },
  { id: "Magister (S2)", name: "Magister (S2)" },
  { id: "Doktor (S2)", name: "Doktor (S2)" },
];
