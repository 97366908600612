import React from "react";
import Title from "../../texts/title";
import FormCreateBlog from "../../forms/blog/create";
import { Box, Divider } from "@material-ui/core";

const CreateBlog = () => {
  return (
    <>
      <Title title="Tulis Artikel baru" />
      <Divider />
      <Box mt={2}>
        <FormCreateBlog />
      </Box>
    </>
  );
};

export default CreateBlog;
