import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import BlogPage from "./pages/blogs";
import { ProtectedRoute } from "./auth/protected-route";

import CreateBlogPage from "./pages/blogs/create-blog";
import EditBlogPage from "./pages/blogs/edit-blog";
import JobVacancyPage from "./pages/job-vacancy";
import CreateJobPage from "./pages/job-vacancy/create-job";
import JobCompanyPage from "./pages/job-company";
import CreateCompanyPage from "./pages/job-company/create-company";
import EditCompanyPage from "./pages/job-company/edit-company";
import EditJobPage from "./pages/job-vacancy/edit-job";

function App() {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={HomePage} />
        <Route path="/login" component={LoginPage} />
        <ProtectedRoute path="/blog/create" component={CreateBlogPage} />
        <ProtectedRoute path="/blog/edit/:id" component={EditBlogPage} />
        <ProtectedRoute path="/blog" component={BlogPage} />
        <ProtectedRoute path="/job-vacancy/edit/:id" component={EditJobPage} />
        <ProtectedRoute path="/job-vacancy/create" component={CreateJobPage} />
        <ProtectedRoute path="/job-vacancy" component={JobVacancyPage} />
        <ProtectedRoute
          path="/job-company/edit/:id"
          component={EditCompanyPage}
        />
        <ProtectedRoute
          path="/job-company/create"
          component={CreateCompanyPage}
        />
        <ProtectedRoute path="/job-company" component={JobCompanyPage} />
      </Switch>
    </Router>
  );
}

export default App;
