import React from "react";
// import {
//   FormControl,
//   FormHelperText,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@material-ui/core";

import styles from "../form.module.css";
import { ucWords } from "../../../utils/converter";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

interface ISelectField {
  fieldName: string;
  setFormValue: any;
  options: any;
  fieldTitle?: string;
  fieldValue?: any;
  fieldPlaceholder?: string;
  fieldHelperText?: string;
  isUcword?: boolean;
}

export const SelectField = ({
  fieldName,
  setFormValue,
  options,
  fieldTitle,
  fieldValue,
  fieldPlaceholder,
  fieldHelperText,
  isUcword,
}: ISelectField) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <FormControl className={styles.formControl} fullWidth={true}>
        <InputLabel id={fieldName}>{fieldTitle}</InputLabel>
        <Select
          variant="outlined"
          labelId={fieldName}
          id={fieldName}
          name={fieldName}
          defaultValue={fieldValue}
          value={fieldValue || ""}
          onChange={(e) => handleChange(e)}
        >
          <MenuItem value="">
            <em>{fieldPlaceholder}</em>
          </MenuItem>
          {options &&
            options.map((item: { id: number; name: string }) => (
              <MenuItem value={item.id} key={item.id}>
                {isUcword ? ucWords(item.name) : item.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{fieldHelperText}</FormHelperText>
      </FormControl>
    </>
  );
};
