import React from "react";
import { Typography } from "@material-ui/core";

const Title = (props: { title: React.ReactNode }) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.title}
    </Typography>
  );
};

export default Title;
