import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Box, Divider } from "@material-ui/core";

import Title from "../texts/title";
import api from "../../utils/api";
import FormEditCompany from "../forms/company/edit";

const EditCompany = () => {
  const match: any = useRouteMatch();
  const [company, setCompany] = useState<any>();

  const token = localStorage.getItem("t_am");

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  useEffect(() => {
    const getBlogs = async () => {
      const { data: res } = await api.get(`job-company/${match.params?.id}`);
      setCompany(res.data);
    };
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Artikel" />
      <Divider />
      <Box mt={2}>
        <FormEditCompany company={company} />
      </Box>
    </>
  );
};

export default EditCompany;
