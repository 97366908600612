import React from "react";
import TextField from "@mui/material/TextField";

interface ITextInputField {
  fieldName: string;
  setFormValue: any;
  fieldLabel: string;
  fieldPlaceholder?: string;
  fieldValue?: any;
  fieldType?: string;
}

export const TextInputField = ({
  fieldName,
  setFormValue,
  fieldLabel,
  fieldValue,
  fieldPlaceholder,
  fieldType,
}: ITextInputField) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <TextField
        label={fieldLabel}
        id={fieldName}
        type={fieldType || "text"}
        name={fieldName}
        onChange={handleChange}
        fullWidth={true}
        placeholder={fieldPlaceholder}
        defaultValue={fieldValue}
        value={fieldValue || ""}
        variant="outlined"
      />
    </>
  );
};
