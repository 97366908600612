import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import api from "../../utils/api";
import { DateIndonesia, Rupiah } from "../../utils/converter";
import { MdCreate } from "react-icons/md";
import { Chip } from "@mui/material";

interface IJob {
  id: number;
  uuid: string;
  title: string;
  address: string;
  salary_from: number;
  salary_to: number;
  total_work: number;
  expired_at: string;
  status: string | number;
  createdAt: string;
  job_company: {
    id: number;
    name: string;
    image: string;
  };
}

const TableListJobs = () => {
  const [jobs, setJobs] = useState<IJob[]>([]);

  const getBlogs = async () => {
    try {
      const { data } = await api.get(
        `/job-vacancy?limit=15&order_by=id&sort_by=desc`
      );
      setJobs(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Lowongan Kerja
            </Typography>
          </Grid>
          <Grid item>
            <Link to="/job-vacancy/create">
              <Button
                variant="contained"
                color="primary"
                startIcon={<MdCreate />}
              >
                Tulis Loker
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      {/* TODO: Create filter by job name */}
      {/* <Box pb={2}>
        <Grid container>
          <Grid item sm={4}>
            <input type="text" placeholder="Cari perusahaan" className="" />
          </Grid>
        </Grid>
      </Box> */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Pekerjaan</TableCell>
            <TableCell>Perusahaan</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Tanggal Terbit</TableCell>
            <TableCell align="center">EXP</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right" width={180}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs &&
            jobs?.map((job: IJob) => (
              <TableRow key={job.id}>
                <TableCell>{job.title}</TableCell>
                <TableCell>{job?.job_company?.name}</TableCell>
                <TableCell align="center">{Rupiah(job.total_work)}</TableCell>
                <TableCell align="center">
                  {DateIndonesia(job.createdAt)}
                </TableCell>
                <TableCell align="center">
                  {DateIndonesia(job.expired_at)}
                </TableCell>
                <TableCell align="center">
                  {job?.status === 0 ? (
                    <Chip
                      label="Tidak tampil"
                      variant="outlined"
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Tampil"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/job-vacancy/edit/${job?.id}`}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ marginRight: "6px" }}
                    >
                      Edit
                    </Button>
                  </Link>
                  <Button variant="outlined" size="small" color="secondary">
                    Hapus
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableListJobs;
