import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  IconButton,
  Button,
} from "@material-ui/core";
// import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";
import CloseIcon from "@material-ui/icons/Close";

import styles from "../form.module.css";
import api from "../../../utils/api";
import LoadingButtonAnimation from "../../../utils/loading";
import { RadioField, RichtextEditor } from "../fields";

interface IFormValue {
  blog_category_id: number | null;
  title: string;
  content: string;
  tags: string;
  image_thumb: string;
  image: string;
  status: string;
}

const FormCreateBlog = () => {
  const [formValue, setFormValue] = useState<IFormValue>({
    blog_category_id: null,
    title: "",
    content: "",
    tags: "",
    image_thumb: "",
    image: "",
    status: "",
  });
  const [categories, setCategories] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [photos, setPhotos] = useState<any>({
    photo: {
      thumb: "",
      large: "",
    },
  });
  const [token, setToken] = useState<string | null>(null);

  const history = useHistory();

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data: res } = await api.get("blog-category");
        setCategories(res.data);
      } catch (error) {}
    };
    getCategories();

    const getToken = localStorage.getItem("t_am");
    if (getToken !== "") setToken(getToken);
  }, []);

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const onSelectFile = async (e: any) => {
    setIsLoading(true);
    handleImageUpload(e);
  };

  async function handleImageUpload(event: {
    target: { files: any[]; name: string };
  }) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    setIsFormReady(false);
    try {
      const compressedFile = await imageCompression(imageFile, options);
      api.defaults.headers.ContentType = "multipart/form-data";
      const formData = new FormData();
      formData.append("file", compressedFile);
      const uploadImg = await api.post("blogs/upload-image/s3", formData);
      if (uploadImg) {
        const newPhoto: any = {
          thumb: uploadImg.data.thumb,
          large: uploadImg.data.large,
        };
        setPhotos({ ...photos, photo: newPhoto });
      }
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      // router.push("/auth/login");
    }
    setIsLoading(false);
  }

  const handleDeleteImage = async () => {
    try {
      const removedPhotos: any = { thumb: photos.thumb, large: photos.large };
      await api.delete("products/delete-image/s3", removedPhotos);
      setPhotos(null);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (
      formValue.blog_category_id &&
      formValue.title !== "" &&
      formValue.tags !== "" &&
      formValue.content !== ""
    ) {
      setIsFormReady(true);
    } else {
      setIsFormReady(false);
    }
  }, [
    formValue.blog_category_id,
    formValue.content,
    formValue.tags,
    formValue.title,
  ]);

  useEffect(() => {
    setFormValue({
      ...formValue,
      image_thumb: photos?.photo?.thumb || "",
      image: photos?.photo?.large || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const handleSubmitFormProduct = async () => {
    if (isFormReady) {
      try {
        await api.post("blogs", formValue);
        history.push("/blog");
      } catch (error) {}
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <FormControl
            className={styles.formControl}
            variant="outlined"
            fullWidth={true}
          >
            <InputLabel id="blog_category_id">Kategori</InputLabel>
            <Select
              labelId="blog_category_id"
              id="blog_category_ids"
              name="blog_category_id"
              value={formValue?.blog_category_id}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="">
                <em>Pilih kategori</em>
              </MenuItem>
              {categories &&
                categories.map((category: { id: number; name: string }) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Pilih kategori yang paling sesuai</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
            id="title"
            label="Judul"
            type="title"
            name="title"
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            placeholder="Tuliskan judul artikel!"
          />
        </Box>
        <Box mb={2}>
          {/* <Editor
            apiKey={process.env.REACT_APP_TINYMCE}
            value={formValue?.content}
            init={{
              height: 500,
              menubar: true,
              plugins: [
                "advlist autolink lists link charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: `formatselect fontsizeselect forecolor blockquote | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat`,
            }}
            onEditorChange={(e) => setFormValue({ ...formValue, content: e })}
          /> */}
          <RichtextEditor fieldName="content" setFormValue={setFormValue} />
        </Box>
        <Box mb={2}>
          {formValue?.image_thumb !== "" ? (
            <div className={styles.wrapImg}>
              <img src={photos?.photo?.thumb} alt="img" />
              <IconButton
                aria-label="delete"
                className={styles.btnDeleteImage}
                onClick={handleDeleteImage}
              >
                <CloseIcon className={styles.iconDeleteImg} />
              </IconButton>
            </div>
          ) : (
            <>
              <input
                id="file"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                className={styles.inputFile}
                onChange={(e) => onSelectFile(e)}
              />
              <label htmlFor="file">Sisipkan Gambar</label>
            </>
          )}
        </Box>
        <Box mb={2}>
          <TextField
            id="tags"
            label="Tag"
            type="text"
            name="tags"
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            placeholder="Tuliskan tag, pisahkan dengan koma!"
          />
        </Box>
        <Box mb={2}>
          <RadioField
            fieldLabel="Status"
            fieldName="status"
            setFormValue={setFormValue}
            fieldValue={formValue.status}
            options={[
              { id: 0, name: "Tidak tampil" },
              { id: 1, name: "Tampil" },
            ]}
          />
        </Box>
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!isFormReady || isLoading}
            style={{ marginRight: "10px" }}
            onClick={handleSubmitFormProduct}
          >
            {isLoading ? <LoadingButtonAnimation /> : "Simpan Artikel"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => history.goBack()}
            disabled={isLoading}
          >
            Batal
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormCreateBlog;
