import React from "react";
import { Box, Divider } from "@material-ui/core";

import Title from "../texts/title";
import FormCreateJob from "../forms/job/create";

const CreateJob = () => {
  return (
    <>
      <Title title="Tulis Lowongan Kerja baru" />
      <Divider />
      <Box mt={2}>
        <FormCreateJob />
      </Box>
    </>
  );
};

export default CreateJob;
