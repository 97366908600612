import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider } from "@material-ui/core";

import Title from "../texts/title";
import api from "../../utils/api";
import FormEditJob from "../forms/job/edit";

const EditJob = () => {
  const { id: jobID }: any = useParams();
  const [job, setJob] = useState<any>();

  const token = localStorage.getItem("t_am");

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  useEffect(() => {
    const getBlogs = async () => {
      const { data: res } = await api.get(`job-vacancy/${jobID}`);
      setJob(res.data);
    };
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Lowongan Kerja" />
      <Divider />
      <Box mt={2}>
        <FormEditJob job={job} />
      </Box>
    </>
  );
};

export default EditJob;
