import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import debounce from "lodash/debounce";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";

import api from "../../../utils/api";
import LoadingButtonAnimation from "../../../utils/loading";
import { ucWords } from "../../../utils/converter";
import { RichtextEditor, TextInputField } from "../fields";
import {
  IJobCompany,
  IJobVacancy,
  IJobVacancyPayload,
} from "../../../shared/interface";
import { educations, typeWorks } from "../../../shared/constants";
import { JobVacancyDto } from "./dots/job-vacancy-dto";

interface IFormEditJob {
  job: IJobVacancy;
}

interface IOption {
  id: number;
  name: string;
}

const FormEditJob = ({ job }: IFormEditJob) => {
  const [formValue, setFormValue] = useState<IJobVacancyPayload>(
    JobVacancyDto(job)
  );
  const [jobFieldTypes, setJobFieldTypes] = useState<IOption[]>([]);
  const [provinces, setProvinces] = useState<IOption[]>([]);
  const [cities, setCities] = useState<IOption[]>([]);
  const [districts, setDistricts] = useState<IOption[]>([]);
  const [villages, setVillages] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [companyOptions, setCompanyOptions] = useState<any>([]);

  const history = useHistory();
  const { id: jobID }: any = useParams();

  useEffect(() => {
    api.get("/job-field-type").then(({ data }) => setJobFieldTypes(data.data));
    api.get("/area-province").then(({ data }) => setProvinces(data.data));

    const getToken = localStorage.getItem("t_am");
    if (getToken !== "") setToken(getToken);
  }, []);

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  useEffect(() => {
    if (job) {
      setFormValue(JobVacancyDto(job));
      if (job.province_id) {
        api
          .get(`/area-city?area_province_id=${job.province_id}&limit=50`)
          .then(({ data }) => setCities(data.data));
      }
      if (job.city_id) {
        api
          .get(`/area-district?area_city_id=${job.city_id}&limit=50`)
          .then(({ data }) => setDistricts(data.data));
      }
      if (job.district_id) {
        api
          .get(`/area-subdistrict?area_district_id=${job.district_id}&limit=50`)
          .then(({ data }) => setVillages(data.data));
      }
      if (job.job_company.id) {
        setCompanyOptions([
          { id: job.job_company.id, label: job.job_company.name },
        ]);
      }
    }
  }, [job]);

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormValue({ ...formValue, [name]: value });
  // };

  useEffect(() => {
    if (
      formValue.title !== "" &&
      formValue.job_company_id &&
      formValue.description !== "" &&
      formValue.province_id !== 0
    ) {
      setIsFormReady(true);
    } else {
      setIsFormReady(false);
    }
  }, [
    formValue.job_company_id,
    formValue.description,
    formValue.province_id,
    formValue.title,
  ]);

  const handleSubmitFormProduct = async () => {
    if (isFormReady) {
      setIsLoading(true);
      try {
        await api.patch(`job-vacancy/${jobID}`, formValue);
        history.push("/job-vacancy");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const fetchCompanies = async (value: string) => {
    api
      .get(`/job-company?order_by=id&sort_by=desc&name=${value}&limit=15`)
      .then(({ data }) => {
        if (data && data.data) {
          const payload = data.data.map((item: IJobCompany) => ({
            label: item.name,
            id: item.id,
          }));
          setCompanyOptions(payload);
        }
      });
  };

  const fetchCities = async (value: number) => {
    api
      .get(`/area-city?area_province_id=${value}&limit=50`)
      .then(({ data }) => setCities(data.data));
  };

  const fetchDistricts = async (value: number) => {
    api
      .get(`/area-district?area_city_id=${value}&limit=50`)
      .then(({ data }) => setDistricts(data.data));
  };

  const fetchVilalges = async (value: number) => {
    api
      .get(`/area-subdistrict?area_district_id=${value}&limit=50`)
      .then(({ data }) => setVillages(data.data));
  };

  const changeHandler = (e: { target: { value: any; name: string } }) => {
    const { value, name } = e.target;
    if (name === "job_company_id") {
      fetchCompanies(value);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getValue = useCallback((options: IOption[], selectedId: any) => {
    return (
      options.find((el) => el.id.toString() === selectedId?.toString()) || {
        id: 0,
        name: "",
      }
    );
  }, []);

  const handleSelect = (name: string, value: any) => {
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!job) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={1}>
          <TextInputField
            fieldLabel="Nama pekerjaan"
            fieldPlaceholder="Tuliskan nama pekerjaan!"
            fieldName="title"
            setFormValue={setFormValue}
            fieldValue={formValue.title}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={provinces}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchCities(newValue.id);
                    handleSelect("province_id", newValue.id);
                  }
                }}
                value={getValue(provinces, formValue.province_id)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Provinsi" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={cities}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchDistricts(newValue.id);
                    handleSelect("city_id", newValue.id);
                  }
                }}
                value={getValue(cities, formValue.city_id)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Kota/Kabupaten" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={districts}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchVilalges(newValue.id);
                    handleSelect("district_id", newValue.id);
                  }
                }}
                value={getValue(districts, formValue.district_id)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Kecamatan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={villages}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    handleSelect("village_id", newValue.id);
                  }
                }}
                value={getValue(villages, formValue.village_id)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Desa" />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={2}>
          <TextInputField
            fieldLabel="Alamat"
            fieldPlaceholder="Tuliskan alamat!"
            fieldName="address"
            setFormValue={setFormValue}
            fieldValue={formValue.address}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.label}
                options={companyOptions}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    setFormValue((prev) => ({
                      ...prev,
                      job_company_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="job_company_id"
                    label="Pilih perusahaan"
                    onChange={(e) => debouncedChangeHandler(e)}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={typeWorks}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    handleSelect("type_work", newValue.id);
                  }
                }}
                value={getValue(typeWorks, formValue.type_work)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Jenis Pekerjaan" />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1}>
          <RichtextEditor
            fieldName="description"
            setFormValue={setFormValue}
            fieldValue={formValue.description}
            fieldLabel="Deskripsi"
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={jobFieldTypes}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    handleSelect("job_field_type_id", newValue.id);
                  }
                }}
                value={getValue(jobFieldTypes, formValue.job_field_type_id)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Bidang Pekerjaan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option?.name}
                options={educations}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    handleSelect("qualification", newValue.id);
                  }
                }}
                value={getValue(educations, formValue.qualification)}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Pendidikan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Expired Loker"
                inputFormat="dd/MM/yyyy"
                value={formValue.expired_at}
                onChange={(e) =>
                  setFormValue((prev: any) => ({
                    ...prev,
                    expired_at: e,
                  }))
                }
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Gaji Dari"
                fieldPlaceholder="Tuliskan gaji!"
                fieldName="salary_from"
                setFormValue={setFormValue}
                fieldType="number"
                fieldValue={formValue.salary_from}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Gaji Hingga"
                fieldPlaceholder="Tuliskan gaji!"
                fieldName="salary_to"
                setFormValue={setFormValue}
                fieldType="number"
                fieldValue={formValue.salary_to}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Jumlah Lowongan"
                fieldPlaceholder="Tuliskan jumlah lowongan!"
                fieldName="total_work"
                setFormValue={setFormValue}
                fieldType="number"
                fieldValue={formValue.total_work}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1}>
          <RichtextEditor
            fieldName="special_requirements"
            setFormValue={setFormValue}
            fieldValue={formValue.special_requirements}
            fieldLabel="Persyaratan"
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Box mb={1}>
              <TextInputField
                fieldLabel="Keterampilan dibutuhkan"
                fieldPlaceholder="Tuliskan keterampilan yang dibutuhkan, pisahkan dengan koma!"
                fieldName="skills_required"
                setFormValue={setFormValue}
                fieldValue={formValue.skills_required}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <TextInputField
                fieldLabel="URL pendaftaran"
                fieldPlaceholder="Tuliskan URL pendaftaran!"
                fieldName="register_url"
                setFormValue={setFormValue}
                fieldValue={formValue.register_url}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1} mt={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            // disabled={!isFormReady || isLoading}
            style={{ marginRight: "10px" }}
            onClick={handleSubmitFormProduct}
          >
            {isLoading ? <LoadingButtonAnimation /> : "Update Loker"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => history.goBack()}
            disabled={isLoading}
          >
            Batal
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormEditJob;
