import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Autocomplete } from "@mui/material";
import debounce from "lodash/debounce";

import styles from "../form.module.css";
import api from "../../../utils/api";
import LoadingButtonAnimation from "../../../utils/loading";
import { ucWords } from "../../../utils/converter";
import { RichtextEditor, TextInputField } from "../fields";
import {
  IJobCompany,
  IJobVacancyPayload,
  IOption,
} from "../../../shared/interface";
import { educations, typeWorks } from "../../../shared/constants";

const FormCreateJob = () => {
  const [formValue, setFormValue] = useState<IJobVacancyPayload>({
    title: "",
    address: "",
    description: "",
    salary_from: null,
    salary_to: null,
    total_work: null,
    skills_required: "",
    register_url: "",
    status: 1,
    qualification: "",
    type_work: "",
    special_requirements: "",
    job_field_type_id: 0,
    job_company_id: 0,
    expired_at: "",
    province_id: 0,
    city_id: 0,
    district_id: 0,
    village_id: 0,
  });
  const [jobFieldTypes, setJobFieldTypes] = useState<IOption[]>([]);
  const [provinces, setProvinces] = useState<IOption[]>([]);
  const [cities, setCities] = useState<IOption[]>([]);
  const [districts, setDistricts] = useState<IOption[]>([]);
  const [villages, setVillages] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [companyOptions, setCompanyOptions] = useState<any>([]);

  const history = useHistory();

  useEffect(() => {
    api.get("/job-field-type").then(({ data }) => setJobFieldTypes(data.data));
    api.get("/area-province").then(({ data }) => setProvinces(data.data));

    const getToken = localStorage.getItem("t_am");
    if (getToken !== "") setToken(getToken);
  }, []);

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormValue({ ...formValue, [name]: value });
  // };

  useEffect(() => {
    if (
      formValue.title !== "" &&
      formValue.job_company_id &&
      formValue.description !== "" &&
      formValue.province_id !== 0
    ) {
      setIsFormReady(true);
    } else {
      setIsFormReady(false);
    }
  }, [
    formValue.job_company_id,
    formValue.description,
    formValue.province_id,
    formValue.title,
  ]);

  const handleSubmitFormProduct = async () => {
    if (isFormReady) {
      setIsLoading(true);
      try {
        await api.post("job-vacancy", formValue);
        history.push("/job-vacancy");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const fetchCompanies = async (value: string) => {
    api
      .get(`/job-company?order_by=id&sort_by=desc&name=${value}&limit=15`)
      .then(({ data }) => {
        if (data && data.data) {
          const payload = data.data.map((item: IJobCompany) => ({
            label: item.name,
            id: item.id,
          }));
          setCompanyOptions(payload);
        }
      });
  };

  const fetchCities = async (value: number) => {
    api
      .get(`/area-city?area_province_id=${value}&limit=50`)
      .then(({ data }) => setCities(data.data));
  };

  const fetchDistricts = async (value: number) => {
    api
      .get(`/area-district?area_city_id=${value}&limit=50`)
      .then(({ data }) => setDistricts(data.data));
  };

  const fetchVilalges = async (value: number) => {
    api
      .get(`/area-subdistrict?area_district_id=${value}&limit=50`)
      .then(({ data }) => setVillages(data.data));
  };

  const changeHandler = (e: { target: { value: any; name: string } }) => {
    const { value, name } = e.target;
    if (name === "job_company_id") {
      fetchCompanies(value);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // console.log(formValue);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <TextInputField
            fieldLabel="Nama pekerjaan"
            fieldPlaceholder="Tuliskan nama pekerjaan!"
            fieldName="title"
            setFormValue={setFormValue}
            fieldValue={formValue.title}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                options={provinces}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchCities(newValue.id);
                    setFormValue((prev) => ({
                      ...prev,
                      province_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Provinsi" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                disablePortal
                options={cities}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchDistricts(newValue.id);
                    setFormValue((prev) => ({
                      ...prev,
                      city_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Kota/kabupaten" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <Autocomplete
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                disablePortal
                options={districts}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    fetchVilalges(newValue.id);
                    setFormValue((prev) => ({
                      ...prev,
                      district_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Kecamatan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <Autocomplete
                  getOptionLabel={(option) => ucWords(option?.name) || ""}
                  disablePortal
                  options={villages}
                  onChange={(_e, newValue) => {
                    if (newValue && newValue.id) {
                      setFormValue((prev) => ({
                        ...prev,
                        village_id: newValue.id,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pilih Desa/Kelurahan" />
                  )}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box mb={2}>
          <TextInputField
            fieldLabel="Alamat"
            fieldPlaceholder="Tuliskan alamat!"
            fieldName="address"
            setFormValue={setFormValue}
            fieldValue={formValue.address}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Autocomplete
                isOptionEqualToValue={(option: any, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.label}
                options={companyOptions}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    setFormValue((prev) => ({
                      ...prev,
                      job_company_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="job_company_id"
                    label="Pilih perusahaan"
                    onChange={(e) => debouncedChangeHandler(e)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Autocomplete
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                disablePortal
                options={typeWorks}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    setFormValue((prev) => ({
                      ...prev,
                      type_work: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Jenis Pekerjaan" />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1}>
          <RichtextEditor
            fieldName="description"
            setFormValue={setFormValue}
            fieldLabel="Deskripsi"
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box mb={1}>
              <Autocomplete
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                disablePortal
                options={jobFieldTypes}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    setFormValue((prev) => ({
                      ...prev,
                      job_field_type_id: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Bidang Pekerjaan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mb={1}>
              <Autocomplete
                getOptionLabel={(option) => ucWords(option?.name) || ""}
                disablePortal
                options={educations}
                onChange={(_e, newValue) => {
                  if (newValue && newValue.id) {
                    setFormValue((prev) => ({
                      ...prev,
                      qualification: newValue.id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pilih Pendidikan" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Expired Loker"
                inputFormat="dd/MM/yyyy"
                value={formValue.expired_at}
                onChange={(e) =>
                  setFormValue((prev: any) => ({
                    ...prev,
                    expired_at: e,
                  }))
                }
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Gaji Dari"
                fieldPlaceholder="Tuliskan gaji!"
                fieldName="salary_from"
                setFormValue={setFormValue}
                fieldValue={formValue.salary_from}
                fieldType="number"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Gaji Hingga"
                fieldPlaceholder="Tuliskan gaji!"
                fieldName="salary_to"
                setFormValue={setFormValue}
                fieldValue={formValue.salary_to}
                fieldType="number"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Jumlah Lowongan"
                fieldPlaceholder="Tuliskan jumlah lowongan!"
                fieldName="total_work"
                setFormValue={setFormValue}
                fieldValue={formValue.total_work}
                fieldType="number"
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1}>
          <Typography component="div">
            <Box sx={{ fontSize: "default", mb: 1 }}>Persyaratan</Box>
          </Typography>
          <RichtextEditor
            fieldName="special_requirements"
            setFormValue={setFormValue}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Box mb={1}>
              <TextInputField
                fieldLabel="Keterampilan dibutuhkan"
                fieldPlaceholder="Tuliskan keterampilan yang dibutuhkan, pisahkan dengan koma!"
                fieldName="skills_required"
                setFormValue={setFormValue}
                fieldValue={formValue.skills_required}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={1}>
              <TextInputField
                fieldLabel="URL pendaftaran"
                fieldPlaceholder="Tuliskan URL pendaftaran!"
                fieldName="register_url"
                setFormValue={setFormValue}
                fieldValue={formValue.register_url}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={1} mt={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!isFormReady || isLoading}
            style={{ marginRight: "10px" }}
            onClick={handleSubmitFormProduct}
          >
            {isLoading ? <LoadingButtonAnimation /> : "Simpan Loker"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => history.goBack()}
            disabled={isLoading}
          >
            Batal
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormCreateJob;
