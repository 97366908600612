import axios from "axios";

let urls = {
  development: "http://localhost:2021",
  production: "https://api.anekaukm.com",
};

const api = axios.create({
  baseURL: urls[process.env.REACT_APP_NODE_ENV],
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

export default api;
