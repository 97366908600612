import React from "react";
import { Box, Divider } from "@material-ui/core";

import Title from "../texts/title";
import FormCreateCompany from "../forms/company/create";

const CreateCompany = () => {
  return (
    <>
      <Title title="Tambah Perusahaan" />
      <Divider />
      <Box mt={2}>
        <FormCreateCompany />
      </Box>
    </>
  );
};

export default CreateCompany;
