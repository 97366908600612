import React, { Key, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { TiDeleteOutline } from "react-icons/ti";
import { v4 as uuidv4 } from "uuid";

import styles from "./style.module.css";

interface IFile {
  file: string;
  name: string;
  rand_id: number;
  file_type: string;
}

interface IDropzone {
  setFormValue: any;
  name: string;
  label: string;
}

export const Dropzone = ({ setFormValue, name, label }: IDropzone) => {
  const [files, setFiles] = useState<IFile[]>([]);

  const onDrop = useCallback((acceptedFiles) => {
    return acceptedFiles;
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop });

  const compressFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        980,
        580,
        "JPEG",
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      acceptedFiles?.map(async (file) => {
        const file_type = file.type.split("/").pop() || "";
        const resizeFile: any = await compressFile(file);
        updateFileState(resizeFile, file_type);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const updateFileState = (file: any, file_type: string) => {
    const newFile: IFile = {
      rand_id: Math.floor(Math.random() * 1000 * 1),
      name: uuidv4(),
      file,
      file_type,
    };
    setFiles([newFile]);
    setFormValue((prev: any) => ({ ...prev, [name]: file }));
  };

  const handleRemoveFile = (file: any) => {
    const newFiles = files?.filter((el: any) => el.rand_id !== file.rand_id);
    setFiles(newFiles);
    setFormValue((prev: any) => ({ ...prev, [name]: "" }));
  };

  const thumbFiles =
    files &&
    files?.map(
      (
        file: {
          file: string;
          name: string;
          file_type: string;
        },
        i: Key
      ) => (
        <div key={i} className={styles.wrapReview}>
          <div className={styles.reviewImg}>
            <img src={file.file} alt="img" />
          </div>
          <div className={styles.infoReview}>
            <p>{file.name}</p>
            <TiDeleteOutline
              className={styles.iconDelete}
              onClick={() => handleRemoveFile(file)}
            />
          </div>
        </div>
      )
    );

  // useEffect(() => {
  //   if (files && files.length > 0) {
  //     setFormValue((prev: any) => ({ ...prev, [name]: files[0].file }));
  //   }
  // }, [files]);

  return (
    <>
      <h3 className={styles.label}>{label}</h3>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className={styles.dropzone}>Drop the files here ...</div>
        ) : (
          <div className={styles.dropzone}>
            Drag 'n' drop some files here, or click to select files
          </div>
        )}
      </div>
      {thumbFiles}
    </>
  );
};
