import React, { useEffect, useState } from "react";
import Title from "../../texts/title";
import FormEditBlog from "../../forms/blog/edit";
import { Box, Divider } from "@material-ui/core";
import api from "../../../utils/api";
import { useRouteMatch } from "react-router-dom";

const EditBlog = () => {
  const match: any = useRouteMatch();
  const [blog, setBlog] = useState<any>();

  const token = localStorage.getItem("t_am");

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  useEffect(() => {
    const getBlogs = async () => {
      const { data: res } = await api.get(`blogs/${match.params?.id}/admin`);
      setBlog(res.data);
    };
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Artikel" />
      <Divider />
      <Box mt={2}>
        <FormEditBlog blog={blog} />
      </Box>
    </>
  );
};

export default EditBlog;
