import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdCreate } from "react-icons/md";

import api from "../../utils/api";
import { DateIndonesia, Rupiah } from "../../utils/converter";
import Auth from "../../auth/auth";

interface IBlog {
  id: number;
  title: string;
  image_thumb: string;
  hits: number;
  status: number;
  tags: string;
  createdAt: string;
  category: {
    id: number;
    name: string;
    slug: string;
  };
}

const TableListBlogs = () => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [articleScope, setArticleScope] = useState("me");

  const auth = Auth;
  const user: any = auth.user() || {};

  const getBlogs = async () => {
    try {
      const { data } = await api.get(
        `/blogs${articleScope === "me" ? "?user_id=" + user.sub : ""}`
      );
      setBlogs(data.data.rows);
    } catch (error) {}
  };

  useEffect(() => {
    if (articleScope) getBlogs();
  }, [articleScope]);

  return (
    <div>
      <Box pb={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Artikel
            </Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <RadioGroup
                row
                defaultValue="me"
                name="article"
                onClick={(e: any) => setArticleScope(e.target.value)}
              >
                <FormControlLabel
                  value="me"
                  control={<Radio />}
                  label="Artikel Saya"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Semua Artikel"
                />
              </RadioGroup>
            </FormControl>

            <Link to="/blog/create">
              <Button
                variant="contained"
                color="primary"
                startIcon={<MdCreate />}
              >
                Tulis Artikel
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Table size="small" stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>Judul</TableCell>
            <TableCell>Kategori</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Dibaca</TableCell>
            <TableCell align="center">Terbit</TableCell>
            <TableCell align="right" width={180}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blogs &&
            blogs?.map((blog: IBlog) => (
              <TableRow key={blog.id}>
                <TableCell>{blog.title}</TableCell>
                <TableCell>{blog?.category?.name}</TableCell>
                <TableCell align="center">
                  {blog?.status === 0 ? (
                    <Chip
                      label="Tidak tampil"
                      variant="outlined"
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Tampil"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </TableCell>
                <TableCell align="center">{Rupiah(blog.hits)}</TableCell>
                <TableCell align="center">
                  {DateIndonesia(blog.createdAt)}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/blog/edit/${blog?.id}`}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ marginRight: "6px" }}
                    >
                      Edit
                    </Button>
                  </Link>
                  <Button variant="outlined" size="small" color="secondary">
                    Hapus
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableListBlogs;
