import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { MdCreate } from "react-icons/md";

import api from "../../utils/api";
import { Chip } from "@mui/material";

interface ITableListCompanies {
  id: number;
  uuid: string;
  name: string;
  address: string;
  status: number | string;
}

const TableListCompanies = () => {
  const [companies, setCompanies] = useState<ITableListCompanies[]>([]);

  const getCompanies = async () => {
    try {
      const { data } = await api.get(
        `/job-company?limit=15&order_by=id&sort_by=desc`
      );
      setCompanies(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div>
      <Box pb={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Perusahaan
            </Typography>
          </Grid>
          <Grid item>
            <Link to="/job-company/create">
              <Button
                variant="contained"
                color="primary"
                startIcon={<MdCreate />}
              >
                Tambah Perusahaan
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Perusahaan</TableCell>
            <TableCell>Alamat</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right" width={180}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies &&
            companies?.map((company: ITableListCompanies) => (
              <TableRow key={company.id}>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.address}</TableCell>
                <TableCell align="center">
                  {company?.status === 0 ? (
                    <Chip
                      label="Tidak tampil"
                      variant="outlined"
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Tampil"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/job-company/edit/${company?.id}`}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ marginRight: "6px" }}
                    >
                      Edit
                    </Button>
                  </Link>
                  <Button variant="outlined" size="small" color="secondary">
                    Hapus
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableListCompanies;
