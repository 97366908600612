import { IJobCompany } from "../../../../shared/interface";

export const JobCompanyDto = (company: IJobCompany) => ({
  name: company.name,
  job_business_id: company.job_business.id,
  address: company.address,
  province_id: company.province_id,
  city_id: company.city_id,
  district_id: company.district_id,
  village_id: company.village_id,
  description: company.description,
  phone_number: company.phone_number,
  email: company.email,
  website: company.website,
  status: `${company.status}`,
});

export const EditJobCompanyDto = (company: IJobCompany) => ({});
