import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { Typography } from "@mui/material";

import styles from "../form.module.css";
import api from "../../../utils/api";
import LoadingButtonAnimation from "../../../utils/loading";
import { ucWords } from "../../../utils/converter";
import {
  Dropzone,
  RadioField,
  RichtextEditor,
  SelectField,
  TextInputField,
} from "../fields";
import { IJobBusiness, IJobCompanyPayload } from "../../../shared/interface";

interface IOption {
  id: number;
  name: string;
}

const FormCreateCompany = () => {
  const [formValue, setFormValue] = useState<IJobCompanyPayload>({
    name: "",
    job_business_id: 0,
    address: "",
    province_id: 0,
    city_id: 0,
    district_id: 0,
    village_id: 0,
    description: "",
    phone_number: "",
    email: "",
    website: "",
    status: 0,
    image: "",
    cover: "",
  });
  const [provinces, setProvinces] = useState<IOption[]>([]);
  const [cities, setCities] = useState<IOption[]>([]);
  const [districts, setDistricts] = useState<IOption[]>([]);
  const [villages, setVillages] = useState<IOption[]>([]);
  const [jobBusiness, setJobBusiness] = useState<IJobBusiness[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(true);
  const [token, setToken] = useState<string | null>(null);

  const history = useHistory();

  useEffect(() => {
    api.get("/area-province").then(({ data }) => setProvinces(data.data));
    api.get("/job-business").then(({ data }) => setJobBusiness(data.data));

    const getToken = localStorage.getItem("t_am");
    if (getToken !== "") setToken(getToken);
  }, []);

  useEffect(() => {
    if (token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    if (
      formValue.name !== "" &&
      formValue.description !== "" &&
      formValue.province_id !== 0
    ) {
      setIsFormReady(true);
    } else {
      setIsFormReady(false);
    }
  }, [formValue.description, formValue.province_id, formValue.name]);

  const handleSubmitFormProduct = async () => {
    if (isFormReady) {
      setIsLoading(true);
      try {
        await api.post("job-company", formValue);
        history.push("/job-company");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <TextInputField
            fieldLabel="Nama perusahaan"
            fieldPlaceholder="Tuliskan nama perusahaan!"
            fieldName="name"
            fieldValue={formValue.name}
            setFormValue={setFormValue}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <InputLabel id="province_id">Provinsi</InputLabel>
                <Select
                  labelId="province_id"
                  id="province_id"
                  name="province_id"
                  value={formValue?.province_id}
                  onChange={(e) => {
                    handleChange(e);
                    api
                      .get(
                        `/area-city?area_province_id=${e.target.value}&limit=50`
                      )
                      .then(({ data }) => setCities(data.data));
                  }}
                >
                  <MenuItem value="">
                    <em>Pilih provinsi</em>
                  </MenuItem>
                  {provinces &&
                    provinces.map((item: { id: number; name: string }) => (
                      <MenuItem value={item.id} key={item.id}>
                        {ucWords(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <InputLabel id="city_id">Kota/Kabupaten</InputLabel>
                <Select
                  labelId="city_id"
                  id="city_id"
                  name="city_id"
                  value={formValue?.city_id}
                  onChange={(e) => {
                    handleChange(e);
                    api
                      .get(
                        `/area-district?area_city_id=${e.target.value}&limit=50`
                      )
                      .then(({ data }) => setDistricts(data.data));
                  }}
                >
                  <MenuItem value="">
                    <em>Pilih kota/kabupaten</em>
                  </MenuItem>
                  {cities &&
                    cities.map((item: { id: number; name: string }) => (
                      <MenuItem value={item.id} key={item.id}>
                        {ucWords(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <InputLabel id="district_id">Kecamatan</InputLabel>
                <Select
                  labelId="district_id"
                  id="district_id"
                  name="district_id"
                  value={formValue?.district_id}
                  onChange={(e) => {
                    handleChange(e);
                    api
                      .get(
                        `/area-subdistrict?area_district_id=${e.target.value}&limit=50`
                      )
                      .then(({ data }) => setVillages(data.data));
                  }}
                >
                  <MenuItem value="">
                    <em>Pilih kecamatan</em>
                  </MenuItem>
                  {districts &&
                    districts.map((item: { id: number; name: string }) => (
                      <MenuItem value={item.id} key={item.id}>
                        {ucWords(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <SelectField
                fieldValue={formValue.village_id}
                fieldName="village_id"
                setFormValue={setFormValue}
                options={villages}
                fieldTitle="Desa/Kelurahan"
                fieldPlaceholder="Pilih desa/kelurahan"
                isUcword
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Alamat"
                fieldPlaceholder="Tuliskan alamat!"
                fieldName="address"
                setFormValue={setFormValue}
                fieldValue={formValue.address}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <TextInputField
                fieldLabel="Website"
                fieldPlaceholder="Tuliskan website!"
                fieldName="website"
                setFormValue={setFormValue}
                fieldValue={formValue.website}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <SelectField
                  fieldValue={formValue.job_business_id}
                  fieldName="job_business_id"
                  setFormValue={setFormValue}
                  options={jobBusiness}
                  fieldTitle="Bidang Pekerjaan"
                  fieldPlaceholder="Pilih bidang pekerjaan"
                  isUcword
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <TextInputField
                  fieldLabel="No Telp"
                  fieldPlaceholder="Tuliskan no telp!"
                  fieldType="number"
                  fieldName="phone_number"
                  setFormValue={setFormValue}
                  fieldValue={formValue.phone_number}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <FormControl className={styles.formControl} fullWidth={true}>
                <TextInputField
                  fieldLabel="Email"
                  fieldPlaceholder="Tuliskan email!"
                  fieldType="email"
                  fieldName="email"
                  setFormValue={setFormValue}
                  fieldValue={formValue.email}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box mb={2}>
          <RichtextEditor
            fieldName="description"
            setFormValue={setFormValue}
            fieldLabel="Deskripsi"
          />
        </Box>

        <Box mb={2}>
          <Dropzone
            setFormValue={setFormValue}
            name="image"
            label="Logo Perusahaan"
          />
        </Box>

        <Box mb={2}>
          <Dropzone
            setFormValue={setFormValue}
            name="cover"
            label="Gambar Cover"
          />
        </Box>

        <Box mb={2}>
          <RadioField
            fieldLabel="Status"
            fieldName="status"
            setFormValue={setFormValue}
            fieldValue={formValue.status}
            options={[
              { id: 0, name: "Tidak tampil" },
              { id: 1, name: "Tampil" },
            ]}
          />
        </Box>
      </Grid>

      <Box mb={2} mt={2}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!isFormReady || isLoading}
          style={{ marginRight: "10px" }}
          onClick={handleSubmitFormProduct}
        >
          {isLoading ? <LoadingButtonAnimation /> : "Simpan Perusahaan"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => history.goBack()}
          disabled={isLoading}
        >
          Batal
        </Button>
      </Box>
    </Grid>
  );
};

export default FormCreateCompany;
