import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import api from "../../../utils/api";

interface IRichtextEditor {
  fieldName: string;
  setFormValue: any;
  fieldValue?: string;
  fieldLabel?: string;
}

export const RichtextEditor = ({
  fieldName,
  setFormValue,
  fieldValue,
  fieldLabel,
}: IRichtextEditor) => {
  const handleUploadImage = (blobInfo: any, success: any, failure: any) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());
    const reader = new FileReader();
    reader.readAsDataURL(blobInfo.blob());
    reader.onloadend = function () {
      api
        .post(`blogs/upload-file/image`, { file: reader.result })
        .then((res) => success(res.data))
        .catch((error) => failure(error.message));
    };
  };
  return (
    <>
      {fieldLabel && (
        <Typography component="div">
          <Box sx={{ fontSize: "default", mb: 1 }}>{fieldLabel}</Box>
        </Typography>
      )}
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE}
        value={fieldValue}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar: `formatselect fontsizeselect image forecolor blockquote | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat`,
          images_upload_handler: handleUploadImage,
        }}
        onEditorChange={(e) =>
          setFormValue((prev: any) => ({ ...prev, [fieldName]: e }))
        }
      />
    </>
  );
};
